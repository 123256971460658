import "devextreme/integration/jquery";
import "devextreme/ui/nav_bar";
import "devextreme/ui/action_sheet";
import "devextreme/ui/tile_view";
import dxtheme from 'devextreme/ui/themes';
//import "../../../Content/0-assets/components/nav-main/nav.scss";
import { itemTypes, CenterType, tdfRights } from "../../enums/enums";
import { LeadForensicsDataCenter } from "../../integrations/leadforensics";
import { IDisplayInfo } from "../../interfaces/interfaces";
import { nav_data_service } from "./nav-ds";
import {
  GetDevice,
  isFullScreen,
  CancelFullScreen,
  FullScreen,
  ShortcutActions,
  UpdateTheme
} from "../../util/allutils";
import { LoadUser, GetItemTypeDisplayInfo, CurrentUser } from "../../infrastructure/context";
import * as Cookies from "js-cookie";
import { SearchRecFav } from "../controls/searchrecfav";
import { RFCenter } from "../../datacenters/recent-favorites"
import { Preferences } from "../../infrastructure/user/preferences";
import { HomeListSelection } from "components/controls/homelistselection";
import { createitem } from "components/items/factory/itemfactory";
import { TemplateManagement } from "../../admin/templatemanager";
import { RaiseEvent } from "infrastructure/events/ui_events";
import { EventTypes, eventNameSpace } from "enums/webevents/enums";

interface INavItemTemplate extends DevExpress.ui.dxNavBarItemTemplate {
  href?: string;
  items?: any[];
  template?: JQuery;
}

interface Theme {
  name: string,
  color1: string,
  color2: string,
  dxDataTheme: string
}

export class Navbar {
  private LoadedItems: INavItemTemplate[];
  NavbarModel: any;
  NavbarContainer: string = GetDevice().isDevice ? "tdffooter" : "tdfnavbar";
  private NavbarObject: DevExpress.ui.dxNavBar;
  DisplayInfo: Array<IDisplayInfo>;
  CreateMenu: DevExpress.ui.dxActionSheet | DevExpress.ui.dxPopover;
  constructor() {
    let theNavbar = this;

    $.when(
      theNavbar.LoadNavBarModel(),
      LoadUser(),
      GetItemTypeDisplayInfo()
    ).done(function (r0, r1, r2: Array<IDisplayInfo>) {
      theNavbar.DisplayInfo = r2;
      //theNavbar.Items = theNavbar.GetItems();
      theNavbar.Render();
      //SMACSS: Make this a media css class
      //if (GetDevice().isDevice) {
      //$("#tdf-main-nav").css({ bottom: "0", position: "fixed" });
      //$("#tdfbody").css("top", 0);
      //} else {
      //$("#tdf-main-nav").css({ top: "0", position: "fixed" });
      // $("#tdfbody").css("top", $("#tdf-main-nav").innerHeight() + 5);
      //}
    });
  }

  private LoadNavBarModel() {
    let theNavbar = this;
    let d = $.Deferred();
    new nav_data_service().ds.load().done(model => {
      theNavbar.NavbarModel = $.isArray(model) ? model[0] : model;

      // TEMPORARY OVERRIDE: When Planning is re-implemented this should be removed
      theNavbar.NavbarModel.AllowPlanning = false;

      d.resolve();
    });
    return d.promise();
  }

  private mapItems(opts) {
    let theNavbar = this;
    let items: any[] = theNavbar[opts.text.replace(/\s/, "")];
    if (!items) {
      if (Navbar[opts.text.replace(/\s/, '')]) {
        items = Navbar[opts.text.replace(/\s/, '')];
      }
      if (theNavbar[opts.icon]) {
        items = theNavbar[opts.icon];
      }
      if (Navbar[opts.icon]) {
        items = Navbar[opts.icon];
      }
    }

    let extraItems = theNavbar.mapExtraItems(
      opts.icon === "user" ? opts.icon : opts.text
    );
    if (extraItems && extraItems.length > 0) {
      if (!items) {
        items = [];
      }
      $.each(extraItems, function (index, extraItem) {
        items.push(extraItem);
      });
    }

    return items;
  }

  private mapExtraItems(menuHeader) {
    let theNavBar = this;
    let extraItems: any[] = [];

    theNavBar[menuHeader.replace(/\s/, "") + "ExtraItems"] = false;

    $.each(theNavBar.NavbarModel.ExtraItems, function (index, item) {
      if (item.MenuHeading.localeCompare(menuHeader) === 0) {
        extraItems.push(item);
        theNavBar[menuHeader.replace(/\s/, "") + "ExtraItems"] = true;
      }
    });

    return extraItems;
  }

  private NavbarItem(opts: INavItemTemplate, hasItems: boolean = false): any {
    let theNavbar = this;
    let theItem: any = opts;
    if (hasItems) {
      theItem.items = theNavbar.mapItems(opts);
    }
    return theItem;
	}

	private get UserHasAnyAdminRight() {
		let theNavbar = this;

		return theNavbar.NavbarModel.AllowScreenDesigner ||
			theNavbar.NavbarModel.AllowUdfPickList ||
			theNavbar.NavbarModel.AllowPermissionManager ||
			theNavbar.NavbarModel.AllowWebAdmin ||
			//theNavbar.NavbarModel.AllowWebSyncService ||
			theNavbar.NavbarModel.AllowFolderStructure ||
			theNavbar.NavbarModel.AllowBIPermissions ||
			theNavbar.NavbarModel.AllowBIPreferences ||
			theNavbar.NavbarModel.AllowExchangeSyncService ||
			theNavbar.NavbarModel.AllowEmailLoggingService ||
			theNavbar.NavbarModel.AllowIntegrations ||
			theNavbar["AdminExtraItems"] ||
			theNavbar.NavbarModel.AllowTemplateManager;

	}



  private get Items() {
    let theNavbar = this;
    if (!theNavbar.LoadedItems) {
      let utilityItem = theNavbar.NavbarItem(
        {
          text: "Utilities",
          icon: "dx-icon fa tdf-utilities-navbar-icon"
        },
        true
      );
      // Note:  We need to set visible after the item is created because the ExtraItems option isn't valid until the items are mapped from the model.
      utilityItem.visible =
        theNavbar.NavbarModel.AllowExpense ||
        theNavbar.NavbarModel.AllowPlanning ||
        theNavbar.NavbarModel.AllowQuotes ||
        theNavbar.NavbarModel.AllowEvents ||
        theNavbar.NavbarModel.AllowChat ||
        theNavbar.NavbarModel.AllowTimeTracker ||
        theNavbar["UtilitiesExtraItems"];

      let adminItem = theNavbar.NavbarItem(
        {
          text: "Admin",
          icon: "dx-icon fa fa-eye"
        },
        true
      );
      // Note:  We need to set visible after the item is created because the ExtraItems option isn't valid until the items are mapped from the model.
			adminItem.visible = theNavbar.UserHasAnyAdminRight;

      theNavbar.LoadedItems = [

        theNavbar.NavbarItem({
          text: "",
          icon: "dx-icon icon-logo15power",
          visible: !(
            GetDevice().isDevice ||
            $(window).innerWidth() ||
            500 < 500
          ),
          html: `${theNavbar.GetLink("/home")}`,
          href: "/home"
        }),

        theNavbar.NavbarItem({
          text: "Home",
          icon: "home",
          href: "/home",
          html: `${theNavbar.GetLink("/home")}Home`
        }),

        theNavbar.NavbarItem({
          text: "Search",
          icon: "search",
        }),
        theNavbar.NavbarItem({
          text: "Recent",
          icon: "clock",
        }),
        theNavbar.NavbarItem({
          text: "Favorites",
          icon: "favorites",
        }),
        theNavbar.NavbarItem(
          {
            text: "Info Center",
            icon: "dx-icon fa fa-database",
            href:
              "/infocenter" /*, html: `${theNavbar.GetLink("/infoCenter")}Info Center`*/,
            visible: theNavbar.NavbarModel.AllowInfoCenter
          },
          true
        ),
        theNavbar.NavbarItem({
          text: "BI",
          icon: "chart",
          href: "/bi",
          html: `${theNavbar.GetLink("/bi")}BI`,
          visible: theNavbar.NavbarModel.AllowBI
        }),
        utilityItem,
        theNavbar.NavbarItem({ text: "Create", icon: "plus" }, true),
        adminItem,
        theNavbar.NavbarItem(
          { icon: "user", text: theNavbar.NavbarModel.WebUser },
          true
        )
      ];
    }
    return theNavbar.LoadedItems;
  }

  private static GetButtonLink(url: string) {
    let href = url ? `href="${url}"` : "";
    let newtab = url ? url.search(/https/i) > -1 : false;
    let theLink: string = !GetDevice().isDevice
      ? `<a ${href} class="NavBarButton dx-button-mode-text dx-button dx-button-normal  dx-widget dx-button-has-icon dx-button-has-text" style="height:35px;" ${
      newtab ? 'target="_blank"' : ""
      }></a>`
      : `<div class="dx-button dx-button-normal dx-widget dx-button-has-icon dx-button-has-text" style="height:35px;"></div>`;
    return theLink;
  }

  private GetLink(url) {
    let href = url ? `href="${url}"` : "";
    let theLink: string = !GetDevice().isDevice ? `<a ${href}></a>` : "";
    return theLink;
  }

  private GetButtonIcon(icon) {
    return `<i class="${icon}"></i>`;
  }

  private GetButtonText(text) {
    return `<div class="dx-button-text">${text}</div>`;
  }

  private GetButtonContent(icon, text) {
    let theNavbar = this;
    return $(
      `<div class="dx-button-content">${theNavbar.GetButtonIcon(
        icon
      )}${theNavbar.GetButtonText(text)}</div>`
    );
  }

  public static get user() {
    let theNavbar = this;
    return [
      //{
      //  text: `Settings`,
      //  icon: `dx-icon fa fa-cog`,
      //  href: "/settings",
      //  html: theNavbar.GetButtonLink(`/settings`),
      //  visible: true
      //},
      //{ text: `Shortcuts`, icon: "dx-icon fa fa-keyboard-o", visible: true },
      //{
      //  text: `Get Support`,
      //  icon: "dx-icon tdf-support-navbar-icon",
      //  href: "//www.tdfsupport.com/",
      //  html: theNavbar.GetButtonLink("//www.tdfsupport.com/"),
      //  visible: true
      //},
      //{
      //  text: `Toggle Fullscreen`,
      //  icon: isFullScreen()
      //    ? "dx-icon dx-icon-revert"
      //    : " dx-icon fa fa-arrows-alt",
      //  href: null,
      //  visible: !GetDevice().isDevice
      //},
      {
        text: 'Themes',
        visible: true,
        template: (data, index, el) => {
          let themeList: Theme[] = [
            {
              name: 'light',
              color1: '#2c699d',
              color2: '#fff',
              dxDataTheme: 'generic.light.compact'
            }, {
              name: 'carmine',
              color1: '#f05b41',
              color2: '#fff',
              dxDataTheme: 'generic.carmine.compact'
            }, {
              name: 'greenmist',
              color1: '#29807b',
              color2: '#f5f5f5',
              dxDataTheme: 'generic.greenmist.compact'
            }, {
              name: 'darkviolet',
              color1: '#9c63ff',
              color2: '#1e1e28',
              dxDataTheme: 'generic.darkviolet.compact'
            }, {
              name: 'darkmoon',
              color1: '#15c7ae',
              color2: '#465672',
              dxDataTheme: 'generic.darkmoon.compact'
            }, {
              name: 'softblue',
              color1: '#3895e1',
              color2: '#fff',
              dxDataTheme: 'generic.softblue.compact'
            }, {
              name: 'dark',
              color1: '#1ca8dd',
              color2: '#2a2a2a',
              dxDataTheme: 'generic.dark.compact'
            }
          ];

          let timeoutIds = [];
          let origThemeName: string;

          let tileview = $('<div />').dxTileView({
            dataSource: themeList,
            baseItemHeight: GetDevice().isDevice ? 30 : 20,
            baseItemWidth: GetDevice().isDevice ? 30 : 20,
            itemMargin: GetDevice().isDevice ? 10 : 7,
            height: GetDevice().isDevice ? '40px' : '60px',
            width: GetDevice().isDevice ? 'inherit' : '110px',
            itemTemplate: (d, i, e) => {

              return e.css(
                'background-image',
                `linear-gradient(45deg, ${d.color1} 50%, ${d.color2} 50%)`

              )
                .mouseenter(() => {
                  timeoutIds.push(
                    setTimeout(() => {
                      origThemeName = themeList.filter(a => {
                        return a.dxDataTheme === dxtheme.current();
                      })[0].name;

                      UpdateTheme(d.name, false);
                    }, 1e3)
                  );
                })
                .mouseleave(() => {
                  timeoutIds.forEach(id => {
                    clearTimeout(id);
                  });

                  if (origThemeName) {
                    UpdateTheme(origThemeName, false);
                    origThemeName = null;
                  }
                })
            },
            onItemClick: (e) => {
              UpdateTheme(e.itemData.name);
              origThemeName = null;
            },

          }).dxTileView('instance');

          return $('<center />').text('Themes').append(
            tileview.element()
          );
        }
      },
      {
        text: 'Profile Settings',
        icon: 'dx-icon dx-icon-user',
        href: "/profile",
        html: Navbar.GetButtonLink('/profile'),
        visible: true
      },
      {
        text: 'Homelist Settings',
        icon: 'dx-icon fa fa-building',
        visible: true
      },
      {
        text: `Log Out`,
        icon: `dx-icon fa fa-sign-out`,
        href: "/home",
        html: Navbar.GetButtonLink(`/home`),
        visible: true
      }
    ];
  }

  private get Admin() {
    let theNavbar = this;
    let model = theNavbar.NavbarModel;
    return [
      {
        text: `Admin Utilities`,
        icon: `dx-icon fa fa-book`,
        href: "/admincenter",
        html: Navbar.GetButtonLink(`/admincenter`),
        visible: theNavbar.UserHasAnyAdminRight
      },
      {
        text: `Company Preferences`,
        icon: `dx-icon fa fa-book`,
        href: "/preferencecenter",
        html: Navbar.GetButtonLink(`/preferencecenter`),
        visible: (CurrentUser.Rights.indexOf(tdfRights.rightCompanyPreferences) != -1)
      },
      {
        text: `Advanced Reporting`,
        icon: `dx-icon fa fa-book`,
        href: "/advancedreporting",
        html: Navbar.GetButtonLink(`/advancedreporting`),
        visible: theNavbar.NavbarModel.AllowAdvancedReporting
      },
      // {
      //   text: `BI Permissions`,
      //   icon: `dx-icon fa tdf-userpermissions-navbar-icon`,
      //   href: "/bipermissions",
      //   html: theNavbar.GetButtonLink(`/bipermissions`),
      //   visible: theNavbar.NavbarModel.AllowBIPermissions
      // },
      // {
      //   text: `BI Preferences`,
      //   icon: `dx-icon fa tdf-userpermissions-navbar-icon`,
      //   href: "/bipreferences",
      //   html: theNavbar.GetButtonLink(`/bipreferences`),
      //   visible: theNavbar.NavbarModel.AllowBIPreferences
      // },
      // {
      //   text: `Email Logging Service`,
      //   icon: `dx-icon fa tdf-userpermissions-navbar-icon`,
      //   href: "/emailloggingservice",
      //   html: theNavbar.GetButtonLink(`/emailloggingservice`),
      //   visible: theNavbar.NavbarModel.AllowEmailLoggingService
      // },
      // {
      //   text: `Exchange Sync Service`,
      //   icon: `dx-icon fa tdf-userpermissions-navbar-icon`,
      //   href: "/exchangesyncservice",
      //   html: theNavbar.GetButtonLink(`/exchangesyncservice`),
      //   visible: theNavbar.NavbarModel.AllowExchangeSyncService
      // },
      // {
      //   text: `Folder Structure`,
      //   icon: `dx-icon fa tdf-userpermissions-navbar-icon`,
      //   href: "/folderstructure",
      //   html: theNavbar.GetButtonLink(`/folderstructure`),
      //   visible: theNavbar.NavbarModel.AllowFolderStructure
      // },
      {
        text: `Integrations`,
        icon: `dx-icon fa fa-plug`,
        href: "/integration",
        html: Navbar.GetButtonLink(`/integration`),
        visible: theNavbar.NavbarModel.AllowIntegrations
      },
      // {
      //   text: `Screen Designer`,
      //   icon: `dx-icon fa fa-desktop`,
      //   href: "/screendesigner",
      //   html: theNavbar.GetButtonLink(`/Screen Designer`),
      //   visible: theNavbar.NavbarModel.AllowScreenDesigner
      // },
      {
        text: `Template Manager`,
        icon: `dx-icon fa fa-desktop`,
        //href: "javascript:(function() { new TemplateManagement.TemplateManager(); })()",
        html: Navbar.GetButtonLink(`#`),
        visible: model.AllowTemplateManager
      },
      // {
      //   text: `UDF Picklist`,
      //   icon: `dx-icon fa fa-list `,
      //   href: "/udfpicklist",
      //   html: theNavbar.GetButtonLink(`/udfpicklist`),
      //   visible: theNavbar.NavbarModel.AllowUdfPickList
      // },
      // {
      //   text: `User Permissions`,
      //   icon: `dx-icon fa tdf-userpermissions-navbar-icon`,
      //   href: "/userpermissions",
      //   html: theNavbar.GetButtonLink(`/userpermissions`),
      //   visible: theNavbar.NavbarModel.AllowPermissionManager
      // },
      // {
      //   text: `Web Admin`,
      //   icon: `dx-icon fa tdf-userpermissions-navbar-icon`,
      //   href: "/webadmin",
      //   html: theNavbar.GetButtonLink(`/webadmin`),
      //   visible: theNavbar.NavbarModel.AllowWebAdmin
      // },
      // {
      //   text: `Web Sync Service`,
      //   icon: `dx-icon fa tdf-userpermissions-navbar-icon`,
      //   href: "/websyncservice",
      //   html: theNavbar.GetButtonLink(`/websyncservice`),
      //   visible: theNavbar.NavbarModel.AllowWebSyncService
      // }
    ];
  }

  private get Create() {
    let theNavbar = this;

    return theNavbar.NavbarModel.CreateMenu;
  }
  private get InfoCenter() {
    let theNavbar = this;

    return theNavbar.NavbarModel.InfoCenterItems;
  }
  private get Utilities() {
    let theNavbar = this;
    let expensename = "";
    let quotename = "";
    let eventname = "";

    let expenseinfo = $.grep(theNavbar.DisplayInfo, (v: IDisplayInfo, i) => {
      return v.TypeNum === itemTypes.itemExpense;
    });
    if (expenseinfo && expenseinfo.length) {
      expensename = expenseinfo[0].DisplayName;
    }
    let quoteinfo = $.grep(theNavbar.DisplayInfo, (v: IDisplayInfo, i) => {
      return v.TypeNum === itemTypes.itemQuote;
    });
    if (quoteinfo && quoteinfo.length) {
      quotename = quoteinfo[0].DisplayName;
    }

    let eventinfo = $.grep(theNavbar.DisplayInfo, (v: IDisplayInfo, i) => {
      return v.TypeNum === itemTypes.itemEvent;
    });
    if (eventinfo && eventinfo.length) {
      eventname = eventinfo[0].DisplayName;
    }
    let utils = [
      {
        text: `${expensename}  Wizard`,
        icon: `dx-icon tdf-expensewizard-navbar-icon`,
        href: `/${expensename}`,
        html: Navbar.GetButtonLink(`/${expensename}`),
        visible: theNavbar.NavbarModel.AllowExpense
      },
      {
        text: `Planning`,
        icon: `dx-icon fa fa-road`,
        href: "/planning/tree",
        html: Navbar.GetButtonLink("/planning/tree"),
        visible: theNavbar.NavbarModel.AllowPlanning
      },
      {
        text: `${quotename} Manager`,
        icon: `dx-icon fa fa-quote-left`,
        href: `/${quotename}Manager`,
        html: Navbar.GetButtonLink(`/${quotename}Manager`),
        visible: theNavbar.NavbarModel.AllowQuotes
      },
      {
        text: `${eventname} Manager`,
        icon: `dx-icon tdf-event-navbar-icon`,
        href: `/${eventname}Manager`,
        html: Navbar.GetButtonLink(`/${eventname}Manager`),
        visible: theNavbar.NavbarModel.AllowEvents
      },
      {
        text: `Chat`,
        icon: `dx-icon fa fa-comments`,
        href: "/home/chat",
        html: Navbar.GetButtonLink("/home/chat"),
        visible: theNavbar.NavbarModel.AllowChat
      },
      {
        text: `Time Tracker`,
        icon: `dx-icon fa fa-clock-o`,
        href: "/TimeTracker",
        html: Navbar.GetButtonLink("/TimeTracker"),
        visible: theNavbar.NavbarModel.AllowTimeTracker
      },
      {
        text: `Lead Forensics`,
        icon: `dx-icon  dx-icon-info`,
        visible: theNavbar.NavbarModel.AllowLeadForensics
      }

      //{
      // removed from menu until the "Search" buttons work on the Combine screen.
      //    text: `Combine Items`,
      //    icon: `dx-icon fa fa-clock-o`,
      //    href: "javascript:(function() { new Admin.CombineItems.ItemCombiner(); })()",
      //    html: theNavbar.GetButtonLink("#"),
      //    visible: true  // theNavbar.NavbarModel.AllowTemplateManager
      //}
    ];

    if (theNavbar.NavbarModel["AllowDodgeAnalytics"]) {
      utils.push({
        text: theNavbar.NavbarModel["AllowDodgeAnalytics"].text,
        href: "",
        visible: true,
        icon: "dx-icon fa fa-external-link",
        html: Navbar.GetButtonLink(
          theNavbar.NavbarModel["AllowDodgeAnalytics"].href
        )
      });
    }


    // if user has mass update rights for at least one item type:

    //utils.push({
    //    text: "Mass Update",
    //    html: Navbar.GetButtonLink("#"),
    //    visible: true,
    //    href: "javascript:(function() { new TDFMassUpdate.MassUpdateDlg(false); })()", icon: ""
    //});

    return utils;
  }

  Render(): void {
    let theNavbar = this;
    theNavbar.NavbarObject = $("#" + theNavbar.NavbarContainer)
      .dxNavBar({
        scrollingEnabled: true, //!(DevExpress.devices.orientation() === "landscape"),
        scrollByContent: true,
        showNavButtons: false,
        selectedIndex: theNavbar.GetLocation(),
        items: theNavbar.Items,
        onItemClick: function (e) {
          if (
            e.itemData.href &&
            e.itemData.text !== "Info Center" &&
            GetDevice().isDevice
          ) {
            window.location.href = e.itemData.href;
          } else {
            if (e.itemData.items) {
              if ($("#navactions").length) {
                $("#navactions").remove();
              }
              $("body").append($("<div id='navactions' />"));

              if (
                GetDevice().isDevice &&
                (e.itemData.text === "Create" ||
                  e.itemData.text === "Info Center")
              ) {
                if (!$("#createpop").length) {
                  $("body").append($("<div id='createpop' />"));
                }

                theNavbar.CreateMenu = $("#createpop")
                  .dxPopup({
                    fullScreen: true,
                    showCloseButton: true,
                    contentTemplate: function (element) {
                      element.append(
                        $("<div />").dxList(<DevExpress.ui.dxListOptions>{
                          items: e.itemData.items,
                          // pageLoadMode: "never",
                          itemTemplate: function (data, index, el) {
                            let icon = data.NameInfo
                              ? `createbtn icon-${data.NameInfo.TypeName.toLowerCase()
                                .replace(/ /g, "_")
                                .replace("(", "")
                                .replace(")", "")
                                .replace(/\//g, '_')
                              }`
                              : `createbtn icon-${data.TypeName.toLowerCase()
                                .replace(/ /g, "_")
                                .replace("(", "")
                                .replace(")", "")
                                .replace(/\//g, '_')
                              }`;
                            let text = data.text;
                            let href = e.itemData.href;
                            let click = e => {
                              //TODO: Figure this out whne not in namespace TDF
                              // let newItem = new (<any>window[data.NameInfo.TypeName])({
                              //   TypeName: data.NameInfo.TypeName,
                              //   ItemType: data.ItemType,
                              //   isNew: true
                              // }).Create();
                              createitem(data.ItemType, {}, true);
                              theNavbar.CreateMenu.hide();
                            };

                            if (
                              e.itemData.visible &&
                              e.itemData.text === "Info Center"
                            ) {
                              text = data.DisplayName;
                              click = e => {
                                window.location.href = `${href}/?id=${
                                  data.ItemType
                                  }`;
                              };
                            }

                            let btn = $("<div />").dxButton({
                              elementAttr: {
                                class: "NavBarButton"
                              },
                              icon: icon,
                              stylingMode: "text",
                              text: text,
                              height: 35,
                              width: "100%",
                              onClick: click
                            });
                            el.append(btn);
                          }
                        })
                      );
                    }
                  })
                  .dxPopup("instance");
                theNavbar.CreateMenu.show();
              } else {
                if (
                  e.itemData.text === "Create" ||
                  e.itemData.text === "Info Center"
                ) {
                  theNavbar.CreateMenu = $("#navactions")
                    .dxActionSheet({
                      items: e.itemData.items,
                      showTitle: false,
                      height: $(window).outerHeight() * 0.7,
                      //width:"250px",
                      itemTemplate: function (data, index, el) {
                        let icon = data.NameInfo
                          ? `createbtn icon-${data.NameInfo.TypeName.toLowerCase()
                            .replace(/ /g, "_")
                            .replace("(", "")
                            .replace(")", "")
                            .replace(/\//g, '_')
                          }`
                          : `dx-icon createbtn icon-${data.TypeName.toLowerCase()
                            .replace(/ /g, "_")
                            .replace("(", "")
                            .replace(")", "")
                            .replace(/\//g, '_')
                          }`;
                        if (
                          e.itemData.visible &&
                          e.itemData.text === "Info Center"
                        ) {
                          el.append(
                            $(
                              Navbar.GetButtonLink(
                                `${e.itemData.href}/?id=${data.ItemType}`
                              )
                            ).append(
                              theNavbar.GetButtonContent(icon, data.DisplayName)
                            )
                          );
                        } else {
                          let btn = $("<div />").dxButton({
                            elementAttr: {
                              class: "NavBarButton"
                            },
                            icon: icon,
                            text: data.text,
                            stylingMode: "text",
                            height: 35,
                            onClick: function (e) {
                              //TODO: Figure this out whne not in namespace TDF
                              // let newItem = new (<any>window[data.NameInfo.TypeName])({
                              //   TypeName: data.NameInfo.TypeName,
                              //   ItemType: data.ItemType,
                              //   isNew: true
                              // }).Create();
                              createitem(data.ItemType, {}, true);
                            }
                          });
                          el.append(btn);
                        }
                      },
                      onContentReady(e) {
                        let container = (e.component as any)._$itemContainer;
                        $(container).dxScrollView({ height: "100%" });
                      }
                    })
                    .dxActionSheet("instance");

                  if (!GetDevice().isDevice) {
                    theNavbar.CreateMenu.option(
                      "target",
                      e.itemElement.children()[0]
                    );
                    theNavbar.CreateMenu.option("usePopover", true);
                  }

                  theNavbar.CreateMenu.show();
                } else {
                  let actionSheet = $("#navactions")
                    .dxActionSheet({
                      items: e.itemData.items,
                      showTitle: false,
                      width: GetDevice().isDevice ? "100%" : "auto",
                      itemTemplate: function (data, index, el) {
                        if (data.visible) {
                          if (data.template) {
                            el.append(data.template(data, index, el));
                          } else {
                            el.append(
                              $(Navbar.GetButtonLink(data.href)).append(
                                theNavbar.GetButtonContent(data.icon, data.text)
                              )
                            );
                          }
                        }
                      },
                      onItemClick(e) {
                        if (e.itemData.text === "Toggle Fullscreen") {
                          isFullScreen()
                            ? CancelFullScreen(document.documentElement)
                            : FullScreen(document.documentElement);
                        } else if (e.itemData.text === "Shortcuts") {
                          ShortcutActions.ListActions();
                        } else if (e.itemData.text === "Homelist Settings") {
                          let homeList = new HomeListSelection();
                          homeList.DisplayPanel();
                        } else if (e.itemData.text === "Template Manager") {
                          new TemplateManagement.TemplateManager();
                        } else {
                          if (e.itemData.text === "Log Out") {
                            Cookies.remove("access");
                            Cookies.remove("refresh_token");
                            Cookies.remove("token_expires");
                            Cookies.remove("api_path");
                            sessionStorage.clear();
                            localStorage.clear();
                          }
                          if (GetDevice().isDevice && e.itemData.href) {
                            window.location.href = e.itemData.href;
                          }
                        }

                        if (e.itemData["onclick"]) {
                          e.itemData["onclick"]();
                        }

                        if (e.itemData.text.search(/Lead Forensics/i) > -1) {
                          let lf = new LeadForensicsDataCenter().ViewAllVisits();
                        }

                        if (e.itemData.text.search(/Dodge Pipeline/i) > -1) {
                          $("#tdfbody").empty();
                          //`<iframe src="${theNavbar.NavbarModel["AllowDodgeAnalytics"].href}"style="height:${height *.8}px; width:100%;border:none;" ></iframe>`
                          let theNavElement = $("#tdf-main-nav");
                          let theFooterElement = $("#tdffooter");
                          let offset = theFooterElement.offset() || { top: 0 };
                          let navheight = theFooterElement.outerHeight() || 75;
                          let height = offset.top - navheight;
                          // let height = $("#tdffooter").offset().top - $("#tdf-main-nav").outerHeight()
                          $("#tdfbody").append(
                            `<iframe src="${
                            theNavbar.NavbarModel["AllowDodgeAnalytics"].href
                            }"style="height:${height}px; width:100%;border:none;" ></iframe>`
                          );
                        }
                      }
                    })
                    .dxActionSheet("instance");

                  if (!GetDevice().isDevice) {
                    if (e.itemElement)
                      actionSheet.option("target", e.itemElement.children()[0]);
                    actionSheet.option("usePopover", true);
                  }
                  actionSheet.show().done(function () {
                    let t = actionSheet.element();
                  });
                }
              }
            }

            let srf: SearchRecFav;
            switch (e.itemData.text) {
              case 'Search':
                srf = new SearchRecFav(itemTypes.itemUnknown, [itemTypes.itemAllItems], itemTypes.itemUnknown, true, 0);
                srf.ReadOnly = true;
                srf.GetSRFDialog();
                break;

              case 'Recent':
                if (Preferences.GetPreference('CompactView_FromNav', 'SearchRecFav', '0') === '0' || GetDevice().isDevice) {
                  srf = new SearchRecFav(itemTypes.itemUnknown, [itemTypes.itemAllItems], itemTypes.itemUnknown, true, 1);
                  srf.ReadOnly = true;
                  srf.GetSRFDialog();
                } else {
                  if (!$("#recentpopup").length) {
                    $("body").append($("<div id='recentpopup' />"));
                  }
                  let pop = $('#recentpopup').dxPopover({
                    target: e.itemElement,
                    height: $('body').innerHeight() / 2,
                    width: '600px',
                    contentTemplate: (element) => {
                      let recFav = new RFCenter(CenterType.Recent, 'Recent_MainNav', element)
                      recFav.UseCards = true;
                      recFav.Init();
                    }
                  }).dxPopover('instance');
                  pop.show();
                }
                break;

              case 'Favorites':
                if (Preferences.GetPreference('CompactView_FromNav', 'SearchRecFav', '0') === '0' || GetDevice().isDevice) {
                  srf = new SearchRecFav(itemTypes.itemUnknown, [itemTypes.itemAllItems], itemTypes.itemUnknown, true, 2);
                  srf.ReadOnly = true;
                  srf.GetSRFDialog();
                } else {
                  if (!$("#favpopup").length) {
                    $("body").append($("<div id='favpopup' />"));
                  }
                  let pop = $('#favpopup').dxPopover({
                    target: e.itemElement,
                    height: $('body').innerHeight() / 2,
                    width: '600px',
                    contentTemplate: (element) => {
                      let recFav = new RFCenter(CenterType.Favorite, 'Fav_MainNav', element);
                      recFav.UseCards = true;
                      recFav.Init();
                    },
                  }).dxPopover('instance');
                  pop.show();
                }
                break;
            }

          }
        }
      })
      .dxNavBar("instance");
  }

  GetLocation(): number {
    let theNavbar = this;
    let index = -1;

    window.location.pathname === "/" ||
      window.location.pathname === "/home/"
      ? (index = 1)
      : (index = -1);
    if (index > -1) return index;
    let utilitiesIndex: number = 0;
    let adminIndex: number = 0;
    let settingsIndex: number = 0;

    $.each(theNavbar.Items as INavItemTemplate, (key: any, val: any) => {
      if (val.text === "Utilities") {
        utilitiesIndex = key;
      }
      if (val.text === "Admin") {
        adminIndex = key;
      }
      if (val.text === theNavbar.NavbarModel.WebUser) {
        settingsIndex = key;
      }

      let path = window.location.pathname;
      if (
        val.href &&
        val.href.replace(/\//g, "").indexOf(path.replace(/\//g, "")) > -1
      ) {
        index = key;
      } else {
        if (
          path.indexOf("quote") > -1 ||
          path.indexOf("expense") > -1 ||
          path.indexOf("plan") > -1 ||
          path.indexOf("event") > -1
        ) {
          index = utilitiesIndex;
        }
        if (
          path.indexOf("screen") > -1 ||
          path.indexOf("expense") > -1 ||
          path.indexOf("picklist") > -1 ||
          path.indexOf("permission") > -1
        ) {
          index = adminIndex;
        }
        if (path.indexOf("setting") > -1) {
          index = settingsIndex;
        }
      }
    });

    return index;
  }
}
