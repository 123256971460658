import Store from "devextreme/data/custom_store";
import data from "devextreme/data/data_source";
import "font-awesome/scss/font-awesome.scss";

import { TDFRequest } from "../../services/request";

export class nav_data_service {
  public get ds(): data {
    return new data({
      store: new Store({
        load(_opts: DevExpress.data.LoadOptions) {
          let dfd = $.Deferred();
          function requestNavModel() {
            var myRequest = new TDFRequest({
              url: "/core/user/navmodel/",
              type: "GET"
            });
            myRequest.MakeRequest().done(function(response: any) {
              if (response) {
                localStorage.setItem("NavBarModel", JSON.stringify(response));
                localStorage.setItem(
                  "NavBarModelTime",
                  new Date().toISOString()
                );
                dfd.resolve(response);
              }
            });
          }

          let localModel = JSON.parse(localStorage.getItem(
            "NavBarModel"
          ) as string);
          let localModelTime: string = localStorage.getItem(
            "NavBarModelTime"
          ) as string;

          if (localModel && localModelTime) {
            let lastUpdated = new Date(localModelTime);
            let today = new Date();
            if (lastUpdated.getDate() === today.getDate()) {
              dfd.resolve(localModel);
            } else {
              localStorage.removeItem("NavBarModel");
              localStorage.removeItem("NavBarModelTime");
              requestNavModel();
            }
          } else {
            requestNavModel();
          }

          return dfd.promise();
        }
      })
    });
  }
}
