/// <reference path="../node_modules/devextreme/dist/ts/dx.all.d.ts"/>
import { Layout } from "./infrastructure/layout";
import '../sass/4-theme/all.scss';
import { Navbar } from './components/nav/mainnavbar';
import { Preferences } from "infrastructure/user/preferences";
import { LoadUser } from "infrastructure/context";
import { UpdateTheme } from "util/allutils";

LoadUser().done(() => {

    let themeName = Preferences.GetPreference('Theme', 'TDFMobile', 'light');

    themeName = themeName.replace(".compact", "");

    setTimeout(() => {
        try {
            UpdateTheme(themeName, false);
        } catch (e) {
            themeName = "light";
            UpdateTheme(themeName);
        }
    }, .01e3);

    if (window["DEBUGGING"]) {
        import('jquery').then((jqMod) => {
            window["jquery"] = jqMod;
        });
    }

    Layout.ConfigureDocumentReady();
    new Navbar();
});

module.exports = {};
