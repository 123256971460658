import { Dialog } from "../components/dialogs/dialog";
import "devextreme/ui/file_uploader";
import "devextreme/ui/tabs";
import "devextreme/ui/form";
import { confirm } from "devextreme/ui/dialog";
import { IDisplayInfo } from "../interfaces/interfaces";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import { TDFRequest } from "../services/request";
import { DomSafeID, GimmeGUID } from "../util/allutils";
import { itemTypes, EnumUDFFormat } from "../enums/enums";
import { LoadCompany, GetItemTypeDisplayInfo } from "../infrastructure/context";
import * as Cookies from "js-cookie";

export namespace TemplateManagement {
  /*
      For building data requests.
    */
  export class TemplateDataService {
    private _templateBase: string = "admin/manage-templates";

    public GetTemplatesRequest(): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${theManager._templateBase}/templates`,
        type: "GET"
      });
    }

    public GetUpdateTemplateRequest(templateID: number, data: any): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${
          theManager._templateBase
          }/templates/${templateID}/set-properties`,
        type: "POST",
        data: data
      });
    }

    public GetBookmarksRequest(templateId: number): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${
          theManager._templateBase
          }/templates/${templateId}/bookmarks`,
        type: "GET"
      });
    }

    public GetEditRequest(templateId: number): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${
          theManager._templateBase
          }/templates/${templateId}/edit`,
        type: "GET"
      });
    }

    public GetCategoryListing(itemType: itemTypes): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${
          theManager._templateBase
          }/category?itemType=${itemType}`,
        type: "GET"
      });
    }

    public UpdateCategory(
      model: TDF_API_ManageTemplates.Models_UpdateCategoryModel
    ): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${theManager._templateBase}/category`,
        type: "PUT",
        data: model
      });
    }

    public DeleteCategory(categoryid: string): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${
          theManager._templateBase
          }/category/${categoryid}`,
        type: "DELETE"
      });
    }

    public AddCategory(
      model: TDF_API_ManageTemplates.Models_AddCategoryModel
    ): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${theManager._templateBase}/category`,
        type: "POST",
        data: model
      });
    }

    public GetTreeRequest(): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${theManager._templateBase}/roots/tree`,
        type: "GET"
      });
    }

    public GetSimpleTemplateListing(
      itemType: itemTypes,
      rootID: string
    ): TDFRequest {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${
          theManager._templateBase
          }/templates-for-item-type?rootID=${rootID}&itemType=${itemType}`,
        type: "GET"
      });
    }

    public SetTemplateAvailability(
      model: TDF_API_ManageTemplates.Models_SetTemplateAvailabilityModel
    ) {
      let theManager = this;
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${
          theManager._templateBase
          }/roots/template-availability`,
        type: "POST",
        data: model
      });
    }

    public SetTemplateProperty(
      templateId: number,
      model: TDF_API_ManageTemplates.SaveTemplateChangesModel
    ) {
      let theManager = this;
      // ' Templates - For - Item - Type
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${
          theManager._templateBase
          }/templates/${templateId}/set-properties`,
        type: "POST",
        data: model
      });
    }

    public DeleteTemplate(templateId: number) {
      let theManager = this;

      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/${
          theManager._templateBase
          }/templates/${templateId}`,
        type: "DELETE"
      });
    }
  }

  export class TemplateManager {
    // When true, a refresh of template data is needed to reflect changes made in other tabs.
    private _templateRefreshNeeded: boolean = false;

    // A cache of raw DisplayInfo information.
    private tdfTypeInfoRaw: IDisplayInfo[];

    // A cache to hold TDF Item Display Info, keyed on TypeNum.
    private tdfTypeInfoByKey: {};

    readonly DataService: TemplateDataService = new TemplateDataService();
    readonly _templateBase: string = "admin/manage-templates";

    // Data containers:
    private _templateCategories: TDF_API_ManageTemplates.Category[] = [];
    private _allowableItemTypes: TDF_API_ManageTemplates.AllowableItemType[] = [];
    private _templateData: TDF_API_ManageTemplates.TemplateGridItem[] = [];

    private _templateGrid: DevExpress.ui.dxDataGrid;
    private _tabControl: DevExpress.ui.dxTabPanel;

    constructor() {
      let theManager = this;

      LoadCompany().done(() => {
        GetItemTypeDisplayInfo().done(e => {
          // cache the raw type info.
          theManager.tdfTypeInfoRaw = e;

          // populate the "by key" object as well:
          theManager.tdfTypeInfoByKey = {};
          $.each(e, (i, val) => {
            theManager.tdfTypeInfoByKey[val.TypeNum] = val;
          });

          theManager.ShowDialog();
        });
      });
      return theManager;
    }

    get CommonGridSettings(): DevExpress.ui.dxDataGridOptions {
      let options: DevExpress.ui.dxDataGridOptions = {
        columnAutoWidth: true,
        columnResizingMode: "widget",
        headerFilter: { visible: true },
        filterRow: { visible: true },
        groupPanel: { visible: true },
        searchPanel: { visible: true },
        rowAlternationEnabled: true
      };

      return options;
    }

    private GetDXTabTemplates(): DevExpress.ui.dxTabPanelItemTemplate[] {
      let theTemplateManager = this;

      return [
        {
          title: "Templates",
          disabled: false,
          template: (data, index, element) => {
            let toolbar = theTemplateManager.GetMainToolbar();

            toolbar.element().appendTo(element);

            theTemplateManager.TemplateGrid.element().appendTo(element);
          }
        },
        {
          title: "Edit Availability",
          template: (data, index, element) => {
            let editor = theTemplateManager.GetTemplateAvailabilityForm();
            element.append(editor);
            //editor.appendTo(element);
          }
        },
        {
          title: "Categories",
          template: (data, index, element) => {
            let form = theTemplateManager.getCategoryForm();
            element.append(form);
          }
        }
      ];
    }

    private GetMainToolbar(): DevExpress.ui.dxToolbar {
      let theTemplateManager = this;

      let toolbarItems: DevExpress.ui.dxPopupToolbarItem[] = [
        {
          widget: "dxButton",
          options: {
            text: "Add",
            icon: "fa fa-plus",
            type: 'success',
            onClick: e => {
              theTemplateManager.AddTemplate();
            }
          }
        },
        {
          widget: "dxButton",
          options: {
            text: "Edit",
            icon: "fa fa-pencil",
            type: 'info',
            onClick: e => {
              let grid = theTemplateManager.TemplateGrid;
              let data = grid.getSelectedRowKeys();
              if (data.length > 0) {
                let id: number = data[0];
                theTemplateManager.EditTemplateWithID(id);
              }
            }
          }
        },
        {
          widget: "dxButton",
          options: {
            text: "Update File",
            icon: "fa fa-refresh",
            onClick: e => {
              let grid = theTemplateManager.TemplateGrid;
              let data = grid.getSelectedRowKeys();
              if (data.length > 0) {
                let id: number = data[0];
                theTemplateManager.UpdateTemplate(id);
              }
            }
          }
        },
        {
          widget: "dxButton",
          options: {
            text: "Delete",
            icon: "fa fa-trash",
            type: 'danger',
            onClick: e => {
              let grid = theTemplateManager.TemplateGrid;
              let data = grid.getSelectedRowKeys();
              if (data.length > 0) {
                theTemplateManager
                  .PromptUserNice(
                    "Are you sure you want to delete the template?"
                  )
                  .done(yes => {
                    if (yes) {
                      let id: number = data[0];
                      theTemplateManager.DataService.DeleteTemplate(id)
                        .MakeRequest()
                        .done(e => {
                          try {
                            let keys = theTemplateManager.TemplateGrid.getSelectedRowKeys();
                            $.each(keys, (index, value) => {
                              let rowIndex = theTemplateManager.TemplateGrid.getRowIndexByKey(
                                value
                              );
                              theTemplateManager.TemplateGrid.removeRow(
                                rowIndex
                              );
                            });
                          } catch (ex) {
                            console.log(ex);
                          }
                        });
                    }
                  });
              }
            }
          }
        }
      ];

      return $("<div />")
        .dxToolbar({
          items: toolbarItems,
          elementAttr: { style: "padding-bottom:6px" }
        }).css("margin-top", "3px")
        .dxToolbar("instance");
    }

    private refreshTemplateData() {
      let theTemplateManager = this;
      let request = theTemplateManager.DataService.GetTemplatesRequest();
      request.MakeRequest().done(d => {
        theTemplateManager._templateData = d.Templates;
        theTemplateManager._templateCategories = d.Categories;
        theTemplateManager.TemplateGrid.option(
          "dataSource",
          theTemplateManager._templateData
        );
        console.log("Template data refreshed.");
      });
    }

    /** Show the main template manager dialog */
    public ShowDialog() {
      let theTemplateManager = this;
      let request = theTemplateManager.DataService.GetTemplatesRequest();
      request.MakeRequest().done(d => {
        theTemplateManager._templateData = d.Templates;
        theTemplateManager._allowableItemTypes = d.AllowableItemTypes;
        theTemplateManager._templateCategories = d.Categories;

        let opts: DevExpress.ui.dxTabPanelOptions = {
          onSelectionChanged: e => {
            if (theTemplateManager._templateRefreshNeeded) {
              if (e.addedItems[0].title == "Templates") {
                theTemplateManager._templateRefreshNeeded = false;
                console.log("Refreshing template data...");
                theTemplateManager.refreshTemplateData();
              }
            }
          },
          items: theTemplateManager.GetDXTabTemplates(),
          swipeEnabled: false,
          height: "100%"
        };

        theTemplateManager._tabControl = $("<div />")
          .dxTabPanel(opts)
          .dxTabPanel("instance");

        //let Content = $("<div />").append(theTemplateManager._toolbar.element()).
        //    append(theTemplateManager._tabControl.element());

        let Content = $("<div />").append(
          theTemplateManager._tabControl.element()
        );

        let dlg = new Dialog(
          {
            body: Content,
            title: "Templates",
            closable: true,
            size: "size-large",
            type: "type-warning",
            showHelpButton: true,
            modal: "yes"
          },
          null,
          true,
          true
        );

        dlg.open().done(() => {
          //theTemplateManager.TemplateGrid.option("height", "50%");

          console.log("Hello! I am a Template Manager.");
        });
      });
    }

    private GetFileUploadOptions(
      templateID?: number
    ): JQueryPromise<DevExpress.ui.dxFileUploaderOptions> {
      let theTemplateManager = this;

      let d: JQueryDeferred<DevExpress.ui.dxFileUploaderOptions> = $.Deferred();

      let url: string = `${TDFRequest.ApiPath}/${
        theTemplateManager._templateBase
        }/templates/upload-file?itemType=1`;

      if (templateID > 0) {
        url = `${TDFRequest.ApiPath}/${
          theTemplateManager._templateBase
          }/templates/${templateID}/upload-file?itemType=1`;
      }

      theTemplateManager.getAuthToken().done(authToken => {

        let fileUploadOptions: DevExpress.ui.dxFileUploaderOptions = {
          elementAttr: { id: "file-uploader" },
          accept: "/*",
          allowCanceling: true,
          disabled: false,
          labelText: "or drop file here",
          multiple: false,
          uploadButtonText: "Upload Template File",
          uploadUrl: url,
          uploadHeaders: { Authorization: "Bearer " + authToken },
          visible: true,
          uploadMode: "useButtons",
          showFileList: true,
          selectButtonText: "Select Template File to Upload",

          onValueChanged: e => {
            console.log(e);
          },

          onUploadError: e => {
            console.log(e);
          },

          onUploaded: function (e) {
            // Our response is the new template.
            let response: any = JSON.parse(e.request.responseText);
            let newID: number = response.TemplateId;

            let result = $.grep(theTemplateManager._templateData, el => {
              return el.TemplateId === newID;
            });

            if (result.length > 0) {
              result[0].TemplateName = response.TemplateName;
              // If i change a property of the item in the datasource, do I "update" the grid as well?
            } else {
              // It's a new one:
              theTemplateManager._templateData.push(response);
            }

            try {
              theTemplateManager.TemplateGrid.refresh();
            } catch (ex) {
              console.log(ex);
            }

            // might be here, might not:
            theTemplateManager
              .PromptUserNice("Would you like to edit the template now?")
              .done(e => {
                if (e === true) theTemplateManager.EditTemplateWithID(newID);
              });
          } // uploaded
        };

        d.resolve(fileUploadOptions);
      });

      return d.promise();
    }

    private PromptUserNice(msg: string): JQueryPromise<boolean> {
      return confirm(msg, "");
    }

    private PromptUser(msg: string): JQueryPromise<boolean> {
      return confirm(msg, "");
    }

    /** Prompt the user to select a new file for upload */
    private AddTemplate() {
      let theTemplateManager = this;
      theTemplateManager.GetFileUploadOptions().done(fileUploadOptions => {

        let instance = $("<div />")
          .dxFileUploader(fileUploadOptions)
          .dxFileUploader("instance");

        let content = instance.element();

        let typeSelector = $("<div />").dxSelectBox({
          elementAttr: { id: "new-template-item-type" },
          dataSource: theTemplateManager._allowableItemTypes,
          displayExpr: "Display",
          valueExpr: "ID",
          noDataText: "Select an item type",
          value: 1,
          onValueChanged: e => {
            let merge: DevExpress.ui.dxFileUploaderOptions = {
              uploadUrl: `${TDFRequest.ApiPath}/${
                theTemplateManager._templateBase
                }/templates/upload-file?itemType=${e.value}`
            };
            instance.option(merge);
          }
        });

        let container = $("<div style='padding:7px' />");
        container.append(
          $(
            "<div><p><strong>Select an item type for the new template</strong></p></div>"
          )
        );
        typeSelector.appendTo(container);

        container.appendTo(content);

        let dlg: Dialog = new Dialog({
          body: content,
          closable: true,
          size: "size-normal",
          title: "Select a template file to upload",
          type: "type-info"
        });
        dlg.open();
      });
    }

    /**
     * Prompt the user to select a new file for upload that will replace an existing template's file
     * @param templateID
     */
    private UpdateTemplate(templateID: number) {
      let theTemplateManager = this;
      theTemplateManager.GetFileUploadOptions(
        templateID
      ).done(fileUploadOptions => {

        let content = $("<div />")
          .dxFileUploader(fileUploadOptions)
          .dxFileUploader("instance")
          .element();
        let dlg: Dialog = new Dialog({
          body: content,
          closable: true,
          size: "size-normal",
          title: "Select a replacement template file to upload",
          type: "type-info"
        });
        dlg.open();
      });
    }

    private get TemplateGrid(): DevExpress.ui.dxDataGrid {
      let theTemplateManager = this;

      if (theTemplateManager._templateGrid) {
        return theTemplateManager._templateGrid;
      }

      let opts = theTemplateManager.CommonGridSettings;

      opts.allowColumnResizing = true;
      opts.allowColumnReordering = true;
      opts.paging = { enabled: true }; //GetDevice().isDevice }

      //opts.pager = { }

      opts.noDataText = "It looks like no templates have been created yet!";
      opts.dataSource = theTemplateManager._templateData;

      opts.selection = {
        mode: "single",
        allowSelectAll: false
      };

      //opts.height = () => { return "660px" }

      opts.keyExpr = "TemplateId";

      opts.columnChooser = { enabled: true, mode: "dragAndDrop" };

      opts.columns = <DevExpress.ui.dxDataGridColumn[]>[
        {
          dataField: "TemplateId",
          visible: false,
          allowEditing: false,
          editorOptions: { visible: false, enabled: false, disabled: true }
        },

        {
          dataField: "TemplateType",
          caption: "Type",
          lookup: {
            valueExpr: "ID",
            displayExpr: "Display",
            dataSource: {
              store: new dxCustomStore({
                key: "ID",
                loadMode: "raw",
                load: () => {
                  let d = $.Deferred();
                  return d.promise(
                    d.resolve(theTemplateManager._allowableItemTypes)
                  );
                },
                byKey: key => {
                  let d = $.Deferred();
                  return d.promise(
                    d.resolve(
                      theTemplateManager._allowableItemTypes.filter(
                        (val, index) => {
                          return val.ID === key;
                        }
                      )
                    )
                  );
                }
              })
            }
          }
        },

        {
          dataField: "CategoryID",

          caption: "Category",

          lookup: {
            valueExpr: "ID",
            displayExpr: "Display",
            dataSource: {
              store: new dxCustomStore({
                load: () => {
                  let d = $.Deferred();
                  return d.promise(
                    d.resolve(theTemplateManager._templateCategories)
                  );
                },
                byKey: key => {
                  let d = $.Deferred();
                  return d.promise(
                    d.resolve(
                      theTemplateManager._templateCategories.filter(
                        (val, index) => {
                          return val.ID === key;
                        }
                      )
                    )
                  );
                }
              })
            }
          }
        },

        { dataField: "TemplateName", visible: false },
        "DisplayName"
      ];

      opts.editing = {
        texts: { confirmDeleteMessage: "" },

        form: <DevExpress.ui.dxFormOptions>{
          customizeItem: e => {
            e.colSpan = 2;
          },

          colCountByScreen: { lg: 2, md: 2, sm: 2, xs: 2 },

          onInitialized: e => {
            let myItems: any[] = e.component.option("items");
            myItems.shift();
            e.component.option("items", myItems);
          }
        },

        mode: "popup",
        allowUpdating: false,
        allowDeleting: false,
        popup: {
          title: "Template Info",
          showTitle: true,
          resizeEnabled: true
        }
      };

      theTemplateManager._templateGrid = $("<div />")
        .dxDataGrid(opts)
        .dxDataGrid("instance");

      return theTemplateManager._templateGrid;
    }

    /**
     * Launch the edit dialog for a single template, given a template id.
     * @param templateID
     */
    private EditTemplateWithID(templateID: number) {
      let theTemplateManager = this;
      let request = theTemplateManager.DataService.GetEditRequest(templateID);
      request
        .MakeRequest()
        .done((data: TDF_API_ManageTemplates.EditTemplateWrapperResponse) => {
          this.EditTemplate(data);
        });
    }

    /**
     * Open a dialog to edit a single bookmark.
     * @param data
     * @param templateData
     * @param onsave
     */
    private EditSingleBookmark(
      data: TDF_API_ManageTemplates.TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark,
      templateData: TDF_API_ManageTemplates.EditTemplateWrapperResponse,
      onsave?: () => void
    ) {
      let theTemplateManager = this;

      // given a target field list with lots of values, extract out the distinct item types so that
      // we can show a tabbed interface to select them.
      let getTypesFromFieldList = (): IDisplayInfo[] => {
        let reduced = templateData.meta.TargetFields.map((value, index) => {
          return value.TypeId;
        });

        let distinctTypes: number[] = [];

        let possible: number[] = [1, 5, 8, 9, 18, 19, 28, 30];

        for (let i = 0; i < possible.length - 1; i++) {
          if (distinctTypes.indexOf(possible[i]) > -1) {
            continue;
          }
          if (reduced.indexOf(possible[i]) > -1) {
            distinctTypes.push(possible[i]);
          }
        }

        return theTemplateManager.tdfTypeInfoRaw.filter((value, index) => {
          return distinctTypes.indexOf(value.TypeNum) >= 0;
        });
      };

      let itemTypeTabData = getTypesFromFieldList();

      $.each(
        templateData.meta.TargetFields,
        (index, value: TDF_API_ManageTemplates.TargetField) => {
          if (value.Display.startsWith("USER: ", 0)) {
            value.TypeId = -1;
          }
        }
      );

      // note - adding "user" as its own tab even though the response from the server will include it with the primary item type (Account)
      itemTypeTabData.push({
        DisplayName: "User",
        TypeNum: -1,
        DisplayNamePlural: "",
        TypeName: "",
        TypeNamePlural: ""
      });

      // Save - this is the list of all target fields. We don't want to display them all at once.
      let allTargetFields = templateData.meta.TargetFields;

      let getFilteredFields = (
        typeId: number
      ): TDF_API_ManageTemplates.TargetField[] => {
        return $.grep(allTargetFields, (el, index) => {
          return el.TypeId == typeId;
        });
      };

      let targetFieldGridOptions = theTemplateManager.CommonGridSettings;

      targetFieldGridOptions.searchPanel = { visible: false };
      targetFieldGridOptions.groupPanel = { visible: false };
      targetFieldGridOptions.dataSource = getFilteredFields(
        itemTypeTabData[0].TypeNum
      );
      targetFieldGridOptions.selection = { mode: "single" };
      targetFieldGridOptions.columns = [
        { dataField: "FieldName", caption: "Target" }
      ];
      targetFieldGridOptions.elementAttr = { id: DomSafeID(GimmeGUID()) };
      targetFieldGridOptions.paging = { enabled: true };
      targetFieldGridOptions.onCellClick = e => {
        if (e.rowType == "data") {
          form.getEditor("FieldName").option("value", e.data.Display);
        }
      };

      let formOptions: DevExpress.ui.dxFormOptions = {
        formData: data,
        colCount: 12,
        colCountByScreen: { lg: 12, sm: 12, md: 12, xs: 12 },

        items: [
          { dataField: "BookmarkName", visible: false },
          { dataField: "BookmarkID", visible: false },

          {
            colSpan: 12,
            dataField: "FieldName",
            label: { text: "Current Target" },
            editorOptions: { disabled: true }
          },

          {
            // this is an empty tab panel meant only to let the user filter the target field grid more quickly.
            colSpan: 12,
            itemType: "simple",
            template: e => {
              let div = $("<div />").dxTabs({
                elementAttr: { id: "the-tabs" + DomSafeID(GimmeGUID()) },

                onInitialized: e => {
                  (e.component as DevExpress.ui.dxTabs).option(
                    "selectedIndex",
                    0
                  );
                },

                onSelectionChanged: e => {
                  let itemType: number = e.addedItems[0].data;
                  let grid = $(
                    `#${targetFieldGridOptions.elementAttr.id}`
                  ).dxDataGrid("instance");
                  grid.option("dataSource", getFilteredFields(itemType));
                  grid.option("paging.pageIndex", 0);
                },

                items: itemTypeTabData.map((value, key) => {
                  return {
                    text: value.DisplayName,
                    data: value.TypeNum,
                    items: []
                  };
                })
              });

              return div;
            }
          },

          {
            itemType: "simple",
            colSpan: 3,
            template: () => {
              let arrow = $(
                "<div class='fa fa-arrow-left fa-4x' style='width:100%;text-align:center;cursor:pointer' />"
              );

              arrow.on("click", e => {
                let grid = form.getEditor("TargetFields");
                let currentIndex = grid.option("paging.pageIndex");
                if (!currentIndex) currentIndex = 0;
                if (currentIndex > 0) {
                  grid.option("paging.pageIndex", currentIndex - 1);
                }
              });

              return arrow;
            }
          },

          <any>{
            colSpan: 6,
            dataField: "TargetFields",
            label: { visible: false },
            editorType: "dxDataGrid",
            editorOptions: targetFieldGridOptions
          },

          {
            colSpan: 3,
            itemType: "simple",

            template: () => {
              let arrow = $(
                "<div class='fa fa-arrow-right fa-4x' style='width:100%;text-align:center;cursor:pointer' />"
              );

              arrow.on("click", e => {
                let grid: DevExpress.ui.dxDataGrid = form.getEditor(
                  "TargetFields"
                );
                let currentIndex = grid.option("paging.pageIndex");
                if (!currentIndex) currentIndex = 0;

                if (currentIndex + 1 < grid.pageCount()) {
                  grid.option("paging.pageIndex", currentIndex + 1);
                }
              });

              return arrow;
            }
          },

          {
            colSpan: 12,
            dataField: "BookmarkFormat",
            editorType: "dxLookup",
            label: { text: "Format" },
            editorOptions: {
              dataSource: templateData.meta.Formats,
              valueExpr: "Value",
              displayExpr: "Display",
              columns: [{ dataField: "Display", caption: "Formats" }]
            }
          }
        ]
      };

      let form: DevExpress.ui.dxForm = new DevExpress.ui.dxForm(
        $("<div />"),
        formOptions
      );

      let dlg: Dialog = new Dialog({
        body: form.element(), // $("<div />").dxForm(formOptions),
        closable: true,
        size: "size-normal",
        title: `Edit Bookmark "${data.BookmarkName}"`,
        buttons: [
          <DevExpress.ui.dxPopupToolbarItem>{
            toolbar: "bottom",
            location: 'after',
            widget: "dxButton",
            options: <DevExpress.ui.dxButtonOptions>{
              text: "Save",
              type: 'success',
              icon: "check",
              onClick: e => {
                // save the changes to the bookmark (locally)
                dlg.close();

                if (onsave) {
                  onsave();
                }
              }
            }
          }, {
            toolbar: 'bottom',
            location: 'after',
            widget: 'dxButton',
            options: <DevExpress.ui.dxButtonOptions>{
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: (e) => {
                dlg.close();
              }
            }
          }
        ],
        showHelpButton: true
      });

      dlg.Instance.option("animation.show", <DevExpress.animationConfig>{
        type: "pop",
        duration: 300,
        to: { opacity: 1, scale: 1 },
        from: { opacity: 0, scale: 0.55 }
      });

      dlg.open();
    }

    /**
     * Launch the edit dialog for a single template, given the raw data for the template.
     * @param templateData
     */
    private EditTemplate(
      templateData: TDF_API_ManageTemplates.EditTemplateWrapperResponse
    ) {
      let theTemplateManager = this;
      let templateid = templateData.data.TemplateID;
      let formDirty: boolean = false;

      let TargetFields = templateData.meta.TargetFields;

      let bookmarkGridOptions: DevExpress.ui.dxDataGridOptions =
        theTemplateManager.CommonGridSettings; // { allowColumnResizing: true };

      bookmarkGridOptions.editing = { allowUpdating: true, mode: "cell" };
      bookmarkGridOptions.selection = { mode: "single" };

      //bookmarkGridOptions.editing.popup = {
      //    showTitle: true, title :"FieldName", width: "350px", height: "350px"
      //}

      bookmarkGridOptions.columns = [
        {
          width: 35,
          dataField: "BookmarkID",
          cellTemplate: (el, info) => {
            return $("<div />").dxButton({ text: "", icon: "fa fa-pencil" });
          },
          caption: " "
        },

        { dataField: "BookmarkName", allowEditing: false, caption: "Bk. Name" },

        {
          dataField: "FieldName",
          caption:
            "Target" /*

		    editCellTemplate: (cellElement, cellInfo) => {

			cellElement.append($("<div />").dxSelectBox({

			dataSource: templateData.meta.TargetFields,
			displayExpr: "Display",
			    onValueChanged: (e) => {
				debugger
			    }
			}))
		    }*/,

          lookup: {
            dataSource: templateData.meta.TargetFields,
            displayExpr: "Display",
            valueExpr: "Display"
          }
        },

        {
          dataField: "BookmarkFormat",
          caption: "Format",
          lookup: {
            dataSource: templateData.meta.Formats,
            valueExpr: "Value",
            displayExpr: "Display"
          }
        },

        {
          dataField: "ExtendedFormat",
          caption: "Adv. Format",
          lookup: {
            dataSource: templateData.meta.AdvancedFormats,
            valueExpr: "ID",
            displayExpr: "DisplayText"
          }
        }
      ];

      bookmarkGridOptions.onRowUpdated = e => {
        if (e.data["FieldName"]) {
          // note e.data = { "NameOfFieldInGridThatWasJustChanged": "NewValue"}
          try {
            let theField = TargetFields.filter((val, index) => {
              return val.Display === e.data["FieldName"];
            })[0];
            (e.key as TDF_API_ManageTemplates.TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark).BookmarkFormat =
              theField.udfFormat;
            (e.key as TDF_API_ManageTemplates.TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark).ValueType =
              theField.ValueType;
          } catch (e) {
            console.log(e);
            (e.key as TDF_API_ManageTemplates.TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark).BookmarkFormat =
              EnumUDFFormat.udfText;
            (e.key as TDF_API_ManageTemplates.TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark).ValueType = 0;
          }
        }

        formDirty = true;
      };

      bookmarkGridOptions.onEditorPreparing = e => {
        try {
          if (e.parentType == "filterRow") return;

          if (e.dataField == "ExtendedFormat") {
            //if (e.row.rowType == "data") {
            // filter our extended format based on the existing format.
            let rowObj = <TDF_API_ManageTemplates.TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark>e
              .row.key;
            if (rowObj.BookmarkFormat > 0) {
              let filteredAdvancedFormats = $.grep(
                templateData.meta.AdvancedFormats,
                (value, index) => {
                  return value.BasicFormat == rowObj.BookmarkFormat;
                }
              );
              (e.editorOptions as DevExpress.ui.dxSelectBoxOptions).dataSource = filteredAdvancedFormats;
            }
            //}
          } else if (e.dataField == "BookmarkFormat") {
            e.editorName = "dxLookup";
            let selectedTarget = <TDF_API_ManageTemplates.TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark>e
              .row.key;
            if (selectedTarget.FieldName != "") {
              // if we have a selected target, we want to determine what the "valuetype" of that field is.
              let matchingField = $.grep(
                templateData.meta.TargetFields,
                (value, index) => {
                  return value.Display == selectedTarget.FieldName;
                }
              );
              if (matchingField.length == 1) {
                if (matchingField[0].ValueType === 1) {
                  // negative values only (Word table-types only)
                  let validTypes = $.grep(
                    templateData.meta.Formats,
                    (el, index) => {
                      return el.Value < 0;
                    }
                  );
                  (e.editorOptions as DevExpress.ui.dxLookupOptions).dataSource = validTypes;
                  //console.log("Should show table values.");
                } else {
                  //console.log("Should show simple values only.");
                  // >= 0: udfFormat values.
                  let validTypes = $.grep(
                    templateData.meta.Formats,
                    (el, index) => {
                      return el.Value >= 0;
                    }
                  );
                  (e.editorOptions as DevExpress.ui.dxLookupOptions).dataSource = validTypes;
                }
              }
            } else {
              // No target selected - limit to simple items.
              let validTypes = $.grep(
                templateData.meta.Formats,
                (el, index) => {
                  return el.Value >= 0;
                }
              );
              (e.editorOptions as DevExpress.ui.dxLookupOptions).dataSource = validTypes;
            }
          } else if (e.dataField == "FieldName") {
            e.editorName = "dxLookup";
          }
        } catch (ex) {
          console.log(ex);
        }
      };

      bookmarkGridOptions.noDataText =
        "It looks like this template doesn't have any bookmarks!";

      bookmarkGridOptions.dataSource = templateData.data.Bookmarks;

      bookmarkGridOptions.onCellClick = e => {
        if (e.rowType != "data") return;

        if (e.column.dataField === "BookmarkID") {
          let theBookmark: TDF_API_ManageTemplates.TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark =
            e.data;
          theTemplateManager.EditSingleBookmark(
            theBookmark,
            templateData,
            () => {
              form
                .dxForm("instance")
                .getEditor("Bookmarks")
                .refresh();
            }
          );
        }
      };

      let formOptions: DevExpress.ui.dxFormOptions = {
        formData: templateData.data,

        onFieldDataChanged: () => {
          formDirty = true;
        },

        items: [
          {
            itemType: "group",
            //caption: "Edit Template Info",
            items: [
              {
                editorType: "dxSelectBox",
                dataField: "ItemType",
                editorOptions: {
                  valueExpr: "ID",
                  displayExpr: "Display",
                  dataSource: theTemplateManager._allowableItemTypes,
                  label: "Item Type"
                },
                helpText:
                  "Indicates the type of item from which this template can be launched."
              },

              {
                editorType: "dxTextBox",
                editorOptions: { label: "File Name" },
                dataField: "TemplateName",
                helpText: "The name of the template file."
              },

              {
                editorType: "dxTextBox",
                editorOptions: { label: "Display Name" },
                dataField: "DisplayName",
                helpText:
                  "The name of the template as it should appear in menus"
              },

              {
                editorType: "dxSelectBox",
                dataField: "CategoryID",
                label: { text: "Category" },
                helpText:
                  "The category for this template. Categorized templates will appear in sub-menus.",
                editorOptions: {
                  valueExpr: "ID",
                  displayExpr: "Display",
                  dataSource: templateData.meta.Categories
                }
              },

              {
                editorType: "dxTagBox",
                dataField: "AcctRoots",
                label: { text: "Available to" },
                helpText:
                  "The Account Roots that this template is available to.",
                editorOptions: <DevExpress.ui.dxTagBoxOptions>{
                  showSelectionControls: true,
                  dataSource: templateData.meta.AcctRoots,
                  valueExpr: "ID",
                  displayExpr: "Name"
                }
              }
            ]
          },

          {
            itemType: "group",
            //caption: "Edit Bookmarks",
            items: [
              <any>{
                label: { visible: false },
                editorType: "dxDataGrid",
                dataField: "Bookmarks",
                editorOptions: bookmarkGridOptions
              }
            ]
          }
        ]
      };

      let form = $("<div />").dxForm(formOptions);

      let saveTemplateData = () => {
        formDirty = false;

        // save the changes the template and the bookmarks.
        let theData = formOptions.formData;
        let theBookmarks: TDF_API_ManageTemplates.TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark[] = bookmarkGridOptions.dataSource as any[];

        let saveModel: TDF_API_ManageTemplates.SaveTemplateChangesModel = {
          CategoryID: theData.CategoryID,
          DisplayName: theData.DisplayName,
          TemplateName: theData.TemplateName,
          ItemType: theData.ItemType,
          Bookmarks: theBookmarks,
          AcctRoots: theData.AcctRoots
        };

        theTemplateManager.DataService.SetTemplateProperty(
          templateid,
          saveModel
        )
          .MakeRequest()
          .done(e => {
            dlg.close();
            this.refreshTemplateData();
          });
      };

      let dlg: Dialog = new Dialog({
        onHiding: e => {
          if (formDirty) {
            e.cancel = true;
            theTemplateManager.PromptUser(
              "You have unsaved changes. Are you sure you want to continue?"
            ).done(answer => {
              if (answer) {
                formDirty = false;
                dlg.close();
              }
            });
          }
        },
        title: "Edit Template/Bookmarks",
        closable: true,
        body: form,
        size: "size-large",
        type: "type-warning",
        buttons: [
          <DevExpress.ui.dxPopupToolbarItem>{
            toolbar: "bottom",
            widget: "dxButton",
            options: {
              text: "Save",
              icon: "check",
              onClick: e => {
                saveTemplateData();
              }
            }
          }, {
            toolbar: 'bottom', widget: 'dxButton', options: {
              text: 'Cancel',
              icon: 'remove',
              type: 'danger',
              onClick: (e) => {
                dlg.close();
              }
            }
          }
        ],
        showHelpButton: true
      });

      dlg.Instance.option("animation.show", <DevExpress.animationConfig>{
        type: "pop",
        duration: 300,
        to: { opacity: 1, scale: 1 },
        from: { opacity: 0, scale: 0.55 }
      });

      dlg.open();
    }

    /** Get a dxForm object for managing the availability of templates to TDF AcctRoots. */
    private GetTemplateAvailabilityForm(): JQuery {
      let theTemplateManager = this;
      let selectedAcctRoot: string = "";

      let TREE_ID: string = DomSafeID(GimmeGUID());
      let GRID_ID: string = DomSafeID(GimmeGUID());
      let ITEM_TYPE_SELECTOR: string = DomSafeID(GimmeGUID());
      let TEMPLATE_GRID: string = DomSafeID(GimmeGUID());

      let treeOptions: DevExpress.ui.dxTreeViewOptions = {
        displayExpr: "Name",
        keyExpr: "ID",
        parentIdExpr: "FolderParentId",
        dataSource: new dxDataSource(new dxCustomStore({
          load: () => {
            let d: JQueryDeferred<any> = $.Deferred();

            new TDFRequest({
              url: '/admin/manage-templates/roots/tree',
              type: 'GET'
            }).MakeRequest().done(response => {
              d.resolve(response);
            });

            return d.promise();
          }
        })),
        dataStructure: "plain",
        expandAllEnabled: true,
        selectionMode: "single",
        onItemClick: e => {
          let selector = $(`#${ITEM_TYPE_SELECTOR}`).dxSelectBox("instance");
          // get the selector value???
          let itemType: itemTypes = selector.option("value");
          selectedAcctRoot = e.node.key;
          if (selectedAcctRoot && selectedAcctRoot != "0") {
            loadTemplatesForRoot(selectedAcctRoot, itemType);
          }
        }

        // todo: how to make this acctroot tree auto expand all?
      };

      let gridOptions: DevExpress.ui.dxDataGridOptions = {
        elementAttr: { id: TEMPLATE_GRID },
        allowColumnResizing: true,
        editing: {
          allowUpdating: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "cell"
        },
        onRowUpdated: e => {
          if (selectedAcctRoot != "") {
            let selectedItem: TDF_API_ManageTemplates.TemplateAvailabilityInfo =
              e.key;
            let model: TDF_API_ManageTemplates.Models_SetTemplateAvailabilityModel = {
              TemplateID: selectedItem.ID,
              Available: selectedItem.Available,
              AcctRootId: selectedAcctRoot
            };
            theTemplateManager.DataService.SetTemplateAvailability(model)
              .MakeRequest()
              .done(e => {
                console.log("Value updated.");
              });
          }
        },
        dataSource: [],
        columns: [
          {
            dataField: "ID",
            visible: false,
            width: 30,
            caption: " ",
            allowEditing: false
          },
          { dataField: "Available", width: 30, caption: " " },
          {
            dataField: "TypeId",
            caption: " ",
            width: 30,
            allowEditing: false,

            cellTemplate: (el, info) => {
              try {
                let icon =
                  "icon-" +
                  theTemplateManager.tdfTypeInfoByKey[
                    info.value as number
                  ].TypeName.toLowerCase();
                return $(`<div class='${icon}' />`);
              } finally {
              }
            }
          },
          { dataField: "Name", caption: "Template", allowEditing: false }
        ]
      };

      let loadTemplatesForRoot = (root: string, itemType: itemTypes) => {
        if (itemType === null) return;

        theTemplateManager.DataService.GetSimpleTemplateListing(itemType, root)
          .MakeRequest()
          .done(data => {
            let grid = $(`#${TEMPLATE_GRID}`).dxDataGrid("instance");
            grid.beginUpdate();
            grid.option("dataSource", data);
            grid.endUpdate();
          });
      };

      let itemTypeDatasource: TDF_API_ManageTemplates.AllowableItemType[] = [
        { ID: 0, Display: "All Item Types" }
      ];
      itemTypeDatasource = itemTypeDatasource.concat(
        theTemplateManager._allowableItemTypes
      );

      let formOptions: DevExpress.ui.dxFormOptions = {
        colCountByScreen: { lg: 2, md: 2, sm: 1, xs: 1 },
        items: [
          {
            colCount: 1,
            itemType: "group",
            items: [
              {
                editorType: "dxSelectBox",
                editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                  onInitialized: e => {
                    (e.component as DevExpress.ui.dxSelectBox).option(
                      "value",
                      0
                    );
                  },
                  elementAttr: { id: ITEM_TYPE_SELECTOR },
                  valueExpr: "ID",
                  displayExpr: "Display",
                  dataSource: itemTypeDatasource
                }
              },
              <any>{
                colSpan: 1,
                editorType: "dxTreeView",
                editorOptions: treeOptions,
                helpText:
                  'This is a list of Account Roots. A template can be "available" to one or more Account Roots. It must be available to at least one Account Root in order to be usable.'
              }
            ]
          }, // end group item,
          // startsecond group
          {
            itemType: "group",
            items: [
              <any>{
                editorType: "dxDataGrid",
                editorOptions: gridOptions,
                name: "grid"
              }
            ]
          }
        ]
      };

      return $("<div id='edit-availability-form' />").dxForm(formOptions);
    }

    private getAuthToken(): JQueryPromise<string> {
      let d: JQueryDeferred<string> = $.Deferred();

      let fakeReq = new TDFRequest({ url: 'fake' });
      fakeReq.GetToken().done(authToken => {
        d.resolve(authToken);
      });

      return d.promise();
    }

    private getCategoryForm(): JQuery {
      // note that if a category is added, removed, or updated, we will mark the "refresh needed" value true.

      let theTemplateManager = this;

      let gridOptions = theTemplateManager.CommonGridSettings;
      gridOptions.searchPanel = { visible: false };
      gridOptions.groupPanel = { visible: false };
      gridOptions.noDataText =
        "No categories found - please select an item type.";

      gridOptions.columns = [
        { dataField: "ID", visible: false },
        { dataField: "Display", caption: "Name of Category" },
        { dataField: "ItemType", visible: false }
      ];

      gridOptions.editing = {
        useIcons: true,
        allowUpdating: true,
        mode: "cell",
        allowDeleting: true,
        texts: {
          confirmDeleteTitle: "Are you sure you want to delete this category?",
          confirmDeleteMessage:
            "Any template using this category will have its category reset to a blank value."
        }
      };

      gridOptions.onRowUpdated = e => {
        let model: TDF_API_ManageTemplates.Models_UpdateCategoryModel = e.key;
        theTemplateManager.DataService.UpdateCategory(model)
          .MakeRequest()
          .done(e => {
            theTemplateManager._templateRefreshNeeded = true;
          });
      };

      gridOptions.onRowRemoved = e => {
        let id = e.key.ID;
        if (id) {
          theTemplateManager.DataService.DeleteCategory(id)
            .MakeRequest()
            .done(e => {
              theTemplateManager._templateRefreshNeeded = true;
            });
        }
      };

      gridOptions.loadPanel = { enabled: false };

      let refreshGrid = (itemType: itemTypes) => {
        theTemplateManager.DataService.GetCategoryListing(itemType)
          .MakeRequest()
          .done(e => {
            (form.getEditor("Categories") as DevExpress.ui.dxDataGrid).option(
              "dataSource",
              e
            );
          });
      };

      let options: DevExpress.ui.dxFormOptions = {
        onFieldDataChanged: e => {
          if (e.dataField == "ItemType" && e.value && e.value > 0) {
            refreshGrid(e.value as itemTypes);
          }
        },

        items: [
          {
            label: { text: "Item Type", location: "top" },
            dataField: "ItemType",
            itemType: "simple",
            editorType: "dxLookup",
            editorOptions: <DevExpress.ui.dxLookupOptions>{
              dataSource: theTemplateManager._allowableItemTypes.sort(
                (a, b) => {
                  return a.Display.localeCompare(b.Display);
                }
              ),
              valueExpr: "ID",
              displayExpr: "Display"
            },

            helpText:
              "The item type for the category. The category will be available for templates of this type."
          },
          <any>{
            label: { text: "Categories", location: "top" },
            itemType: "simple",
            dataField: "Categories",
            editorType: "dxDataGrid",
            editorOptions: gridOptions
          }
        ]
      };

      let form = $("<div />")
        .dxForm(options)
        .dxForm('instance');

      //

      let toolbarItems: DevExpress.ui.dxPopupToolbarItem[] = [
        {
          widget: "dxButton",
          options: {
            text: "Add Category",
            icon: "fa fa-plus",
            type: 'success',
            onClick: e => {
              let itemType: any = (form.getEditor(
                "ItemType"
              ) as DevExpress.ui.dxSelectBox).option("value");
              if (itemType) {
                // is there a nicer way to prompt the user for a value?
                //let input = prompt("Enter a name for the new category.");

                let onAnswer = (name: string) => {
                  let newItem: TDF_API_ManageTemplates.Models_AddCategoryModel = {
                    itemType: itemType,
                    name: name
                  };
                  theTemplateManager.DataService.AddCategory(newItem)
                    .MakeRequest()
                    .done(e => {
                      theTemplateManager._templateRefreshNeeded = true;
                      // push to datasource of grid.
                      refreshGrid(itemType as itemTypes);
                    });
                };

                let dlg = theTemplateManager.ask(
                  "",
                  "Enter a name for the new category.",
                  onAnswer
                );
                dlg.enableButtons(false);
                dlg.open();

                //if (input) {
                //    let newItem: TDF_API_ManageTemplates.Models_AddCategoryModel = { itemType: itemType, name: input }
                //    theTemplateManager.DataService.AddCategory(newItem).MakeRequest().done((e) => {
                //        theTemplateManager._templateRefreshNeeded = true;
                //        // push to datasource of grid.
                //        refreshGrid((itemType as itemTypes));
                //    });
                //}
              }
            }
          }
        }
      ];

        let toolbar = $("<div />").dxToolbar({ items: toolbarItems }).css("margin-top", "3px");

      let content = $("<div />")
        .append(toolbar)
        .append(form.element());

      return content;
    }

    private ask(
      title: string,
      prompt: string,
      withAnswer: (answer: string) => void
    ): Dialog {
      let data = {};

      let content = $("<div />").dxForm({
        colCount: 2,
        formData: data,
        items: [
          {
            colSpan: 2,
            itemType: "simple",
            editorType: "dxTextBox",
            dataField: "Response",
            label: { text: prompt, location: "top" }
          },
          {
            itemType: "group",
            colCount: 2,
            colSpan: 2,
            items: [
              {
                colSpan: 1,
                itemType: "button",
                caption: "OK",
                buttonOptions: {
                  text: "OK",
                  type: "normal",
                  width: "100%",
                  onClick: e => {
                    let answer = data["Response"];
                    dlg.close();
                    try {
                      withAnswer(answer);
                    } catch (ex) {
                      console.log(ex.message);
                    }
                  }
                }
              },
              {
                colSpan: 1,
                itemType: "button",
                buttonOptions: {
                  text: "Cancel",
                  width: "100%",
                  onClick: e => {
                    dlg.close();
                  }
                }
              }
            ]
          }
        ]
      });

      let dlg = new Dialog({
        body: content,
        closable: false,
        size: "size-small",
        title: title,
        type: "type-primary",
        showStandardButtons: "no"
      });

      return dlg;
    }
  }

  export namespace TDF_API_ManageTemplates {
    export interface TemplateAvailabilityInfo {
      Available: boolean;
      ID: number;
      Name: string;
    }

    export interface SaveTemplateChangesModel {
      DisplayName: string;
      TemplateName: string;
      CategoryID: string;
      ItemType: itemTypes;
      Bookmarks: TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark[];
      AcctRoots: string[];
    }

    export interface EditTemplateWrapperResponse {
      data: EditTemplateResponse;
      meta: EditTemplateResponseMeta;
    }

    export interface FormattingChoice {
      Value: number;
      TableType: string;
      Display: string;
      ValueType: number;
    }

    export interface EditTemplateResponse {
      AcctRoots: string[];
      Bookmarks: TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark[];
      TemplateID: number;
      TemplateName: string;
      DisplayName: string;
      ItemType: itemTypes;
      CategoryID: string;
    }

    export interface EditTemplateResponseMeta {
      AcctRoots: any[];
      AdvancedFormats: any[];
      Formats: FormattingChoice[];
      TargetFields: TargetField[];
      Categories: Category[];
    }

    export interface TargetField {
      FieldName: string;
      Display: string;
      TypeId: number;
      ValueType: number;
      udfFormat: EnumUDFFormat;
    }

    export interface Models_SetTemplateAvailabilityModel {
      TemplateID: number;
      AcctRootId: string;
      Available: boolean;
    }

    export interface Models_AddCategoryModel {
      name: string;
      itemType: itemTypes;
    }

    export interface Models_UpdateCategoryModel {
      ID: string;
      Display: string;
      ItemType: itemTypes;
    }

    export interface TDF_Admin_Data_Services_TemplateManagement_DTO_UpdateModel {
      TemplateID: number;
      PropertyName: "TemplateType" | "DisplayName" | "CategoryID";
      NewValue: any;
    }

    export interface Models_SaveBookmarksModel {
      TemplateID: number;
      Bookmarks: Array<TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark>;
    }

    export interface TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark {
      BookmarkID: string;
      BookmarkName: string;
      FieldName: string;
      ItemType: number;
      BookmarkFormat: EnumUDFFormat;
      ExtendedFormat: string;
    }

    export interface TDF_Admin_Data_Services_TemplateManagement_DTO_Bookmark {
      BookmarkID: string;
      BookmarkName: string;
      FieldName: string;
      ItemType: number;
      BookmarkFormat: EnumUDFFormat;
      ExtendedFormat: string;
      ValueType: number;
    }

    export interface Category {
      ID: string;
      Display: string;
      ItemType: number;
    }

    export interface Models_UploadTemplateModel {
      ItemType: itemTypes;
    }

    export interface TemplateGridItem {
      TemplateId: number;
      TemplateName: string;
      DisplayName: string;
      TemplateType: number;
      CategoryID?: number;
    }

    export interface AllowableItemType {
      ID: number;
      Display: string;
    }

    export interface AcctRootTreeItem {
      Name: string;
      FolderId: string;
      FolderParentId: string;
      ID: string;
      FolderLevel: number;
      AllowSelection: boolean;
    }
  }
}
